/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.snackActionText {
    .mdc-snackbar__surface {
        @apply bg-gray-900 #{'!important'};
    }

    button {
        @apply text-primary-500 #{'!important'};
    }
}

.text-secondary {
    color: #000 !important;
}

.px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
}

mat-label {
    color: #000 !important;
}

.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {    
    border-color: rgb(203 213 225 / var(--tw-border-opacity)) !important;
}


.show-hover>div {
    visibility: hidden;
}
.show-hover:hover>div {
      visibility: visible !important;
}

.hover\:bg-hover-dark:hover {
    background-color: #0003 !important;
}

.theme-default .light .mat-step-header .mat-step-icon-selected, .theme-default .light .mat-step-header .mat-step-icon-state-done, .theme-default .light .mat-step-header .mat-step-icon-state-edit, .theme-default.light .mat-step-header .mat-step-icon-selected, .theme-default.light .mat-step-header .mat-step-icon-state-done, .theme-default.light .mat-step-header .mat-step-icon-state-edit {
    background: var(--fuse-primary-600);
}

.grid-cols-13 {
    grid-template-columns: repeat(13,minmax(0,1fr)) !important;
}

.grid-cols-14 {
    grid-template-columns: repeat(14,minmax(0,1fr)) !important;
}

.grid-cols-15 {
    grid-template-columns: repeat(15,minmax(0,1fr)) !important;
}

.grid-cols-16 {
    grid-template-columns: repeat(16,minmax(0,1fr)) !important;
}

@media (min-width: 600px) {
    .sm\:grid-cols-13 {
        grid-template-columns: repeat(13,minmax(0,1fr)) !important;
    }
    
    .sm\:grid-cols-14 {
        grid-template-columns: repeat(14,minmax(0,1fr)) !important;
    }
    
    .sm\:grid-cols-15 {
        grid-template-columns: repeat(15,minmax(0,1fr)) !important;
    }
    
    .sm\:grid-cols-16 {
        grid-template-columns: repeat(16,minmax(0,1fr)) !important;
    }
}


//DLM dialog
.base-popup-dialog {
    width: 30vw;
    min-width: 400px;
    max-height: 80vh;
}

.wide-popup-dialog {
    width: 40vw;
    min-width: 400px;
    max-height: 80vh;
}

mat-dialog-container {
    .dialog-button-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
            width: 50%;
            height: 50px;
            &.mat-flat-button.mat-button-base.mat-accent {
                margin-left: 15px;
            }
        }
    }
}

:root {
    --dlm-blue: #0081ff;
    --dlm-orange: #ff8a48;
    --dlm-amber: #ffac74;
    --dlm-amber-hover: #ffbc90;
    --dlm-light-grey: #cecece;
    --dlm-grey: #ababab;
    --dlm-dark-grey: #a9a9a9;
    --dlm-red: #ff001a;
    --dlm-white: white;
  
    --dlm-purple: #c2077a;
    --dlm-yellow: #edd533;
    --dlm-green: #8bba2e;
    --dlm-pink: #d556a4;
    --dlm-light-blue: #47a2fa;
  }
  